<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="primaryColor || modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="closeOnBackdrop"
      :size="size"
      :centered="centered"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <CButtonClose
          v-if="showCloseButton"
          aria-label="Close"
          class="close"
          @click="modalCallBack(false)"
        />
      </template>
      <div v-if="!innerHTML" class="text-color">{{ modalContent }}</div>
      <div v-html="innerHTML"></div>
      <template #footer>
        <div v-if="stepperModal">
          <span v-for="(button,index) in buttons" :key="`button_${index}`">
            <CButton
              class="ml-2"
              :color="getButtonColor(button)"
              @click="modalCallBack(button)"
              >{{ button.button || button }}</CButton
            >
          </span>
        </div>
        <div v-else>
          <span v-for="(button,index) in buttons" :key="`button_${index}`">
            <CButton
              class="ml-2"
              :color="getButtonColor(button)"
              @click="modalCallBack(button)"
              >{{ button.button || button }}</CButton
            >
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { isEmptyObjectCheck, isObject } from "../../helpers/helper";
export default {
  name: "Modal",
  props: {
    modalTitle: String,
    modalColor: String,
    modalContent: String,
    isShowPopup: Boolean,
    buttons: Array,
    modalCallBack: Function,
    closeOnBackdrop: Boolean,
    stepperModal: Boolean,
    innerHTML: String,
    size: String,
    primaryColor: String,
    secondaryColor: String,
    centered: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShow: this.isShowPopup,
    };
  },
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
  },
  methods: {
    getButtonColor(data) {
      if (isObject(data) && !isEmptyObjectCheck(data) && data?.class) {
        return data?.class;
      }
      if (this.stepperModal)
        return data == "Continue Anyway"
          ? "secondary"
          : this.modalColor;
      return ["Cancel", "No", "No, remove details"].includes(data)
        ? this.secondaryColor || "secondary"
        : this.primaryColor || this.modalColor;
    },
  },
};
</script>
