<template>
  <button
    v-if="isScrollTop"
    @click="scrollToTop"
    id="btn-back-to-top"
    class="btn-floating btn-lg"
    title="Go to top"
  >
    <i class="fas fa-arrow-up c-white"></i>
  </button>
</template>

<script>
export default {
  name: "ScrollToTop",
  data() {
    return {
      isScrollTop: false,
      scrollCanceled: false,
      animationFrameId: null,
    };
  },
  methods: {
    onBodyScroll(e) {
      this.isScrollTop = e.target.scrollTop > 20;
    },
    cancelScroll() {
      // User interaction detected: cancel auto-scroll
      this.scrollCanceled = true;
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
    scrollToTop() {
      // Reset the cancel flag before starting the animation
      this.scrollCanceled = false;
      const container =
        document.getElementById("c-body-app") || document.documentElement;

      const step = () => {
        if (this.scrollCanceled) {
          return; // Stop the animation if canceled
        }
        const currentScroll = container.scrollTop || window.pageYOffset;
        if (currentScroll > 0) {
          this.animationFrameId = requestAnimationFrame(step);
          // Adjust the divisor to slow down or speed up the scrolling
          const delta = currentScroll / 15;
          if (container.scrollTop !== undefined) {
            container.scrollTop = currentScroll - delta;
          } else {
            window.scrollTo(0, currentScroll - delta);
          }
        }
      };

      step();
    },
  },
  mounted() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
      // Listen for user interactions that should cancel the auto-scroll
      ele.addEventListener("wheel", this.cancelScroll);
      ele.addEventListener("touchstart", this.cancelScroll);
    } else {
      window.addEventListener("scroll", this.onBodyScroll);
      window.addEventListener("wheel", this.cancelScroll);
      window.addEventListener("touchstart", this.cancelScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
      ele.removeEventListener("wheel", this.cancelScroll);
      ele.removeEventListener("touchstart", this.cancelScroll);
    } else {
      window.removeEventListener("scroll", this.onBodyScroll);
      window.removeEventListener("wheel", this.cancelScroll);
      window.removeEventListener("touchstart", this.cancelScroll);
    }
  },
};
</script>

<style scoped>
#btn-back-to-top {
  position: fixed;
  background: #ff00ff;
  bottom: 30px !important;
  right: 30px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  border: none !important;  
  outline: none;            
  box-shadow: none;         
  border-color: #ff00ff;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background 0.8s ease;
}
#btn-back-to-top:hover {
  background: rgb(173, 1, 1) !important;
}
#btn-back-to-top:focus,
#btn-back-to-top:active {
  outline: none;
  box-shadow: none;
  border: none;
}
.c-white{
  color: white;
}
</style>
